import React from 'react';
import { graphql } from 'gatsby';
import {
  Intro,
  Section,
  Prefooter,
  Grid,
  Card,
  CardList,
  Content
} from '@partials';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { getSpecsFromProduct } from '../helpers/productHelpers';
import { PageTransition } from '../helpers/pageTransitions';
import { FaPhone } from 'react-icons/fa';
import { t } from '../helpers/language';

const ProductCollection = ({
  data: { products, collection, datoCmsConfig },
  pageContext
}) => {
  const {
    title,
    leadText,
    leadHeader,
    seoMetaTags,
    extendedContentNode,
    pathways,
    showPathways
  } = collection;
  const { contactPage, defaultPathways } = datoCmsConfig;

  const allPathways = [...pathways, ...defaultPathways].slice(0, 3);

  return (
    <>
      <PageTransition>
        <HelmetDatoCms seo={seoMetaTags} />
        <Section>
          <Intro
            title={title}
            breadcrumbs={pageContext.breadcrumbs}
            action={{
              children: t('price_cta'),
              gaTarget: 'product-contact-us',
              to: `/contact-us`
            }}
          ></Intro>
        </Section>

        <Section
          animate={true}
          lead={leadText}
          heading={leadHeader}
          padding={'double'}
          background={'light-gray'}
        >
          <Grid>
            {products.edges.map(({ node }, i) => (
              <Card
                key={`product-${i}`}
                heading={node.title}
                caption={node.shortDescription}
                items={getSpecsFromProduct(node)}
                image={node.heroImage}
                hoverImage={node.hoverImage}
                links={[
                  {
                    path: node.path,
                    title: t('generic_cta')
                  },
                  {
                    path: contactPage.path,
                    gaTarget: 'product-enquiry-cta',
                    state: {
                      product: node
                    },
                    title: t('vortex_contact_cta')
                  }
                ]}
                buttonsRight={true}
              />
            ))}
          </Grid>
        </Section>
        {extendedContentNode && (
          <Section padding={'both'} background={'light-gray'}>
            <Content contentNode={extendedContentNode} />
          </Section>
        )}

        {showPathways && (
          <Section animate={true} background={'light-gray'} padding={'both'}>
            <CardList fullWidth={true}>
              {allPathways.map((pathway, i) => (
                <Card key={`pathway-${i}`} {...pathway} />
              ))}
            </CardList>
          </Section>
        )}
        <Section animate={true}>
          <Prefooter />
        </Section>
      </PageTransition>
    </>
  );
};

export const query = graphql`
  query collectionById($id: String!, $tagId: String!, $language: String!) {
    collection: datoCmsCollection(id: { eq: $id }) {
      slug
      leadText
      leadHeader
      title
      path
      tag {
        name
        slug
        id
      }
      parentLandingPage {
        title
        slug
      }
      extendedContentNode {
        childMarkdownRemark {
          html
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      pathways {
        ...Card
      }
      showPathways
    }
    datoCmsConfig(locale: { eq: $language }) {
      contactPage {
        path
      }
      defaultPathways {
        ...Card
      }
    }

    products: allDatoCmsProduct(
      sort: { fields: position }
      filter: {
        tags: { elemMatch: { id: { eq: $tagId }, locale: { eq: $language } } }
      }
    ) {
      edges {
        node {
          ...ProductCard
        }
      }
    }
  }
`;

export default ProductCollection;
